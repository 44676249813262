import { jwtDecode } from "jwt-decode";
import htt from "./httpService2";

const tokenKey = "token";
export async function login(phone, password) {
  try {
    const { data: jwt } = await htt.post("/Login", {
      phone,
      password,
    });
    return sessionStorage.setItem(tokenKey, jwt);
  } catch (error) {
    alert(error, "Login Failed");
  }
}

export function logout() {
  try {
    return sessionStorage.removeItem(tokenKey);
  } catch (error) {
    alert(error);
  }
}
export async function savePassword(user, data) {
  try {
    return htt.put("/Login/" + user.id, data);
  } catch (error) {
    alert(error);
  }
}
export function getCurrentUser() {
  try {
    const data = sessionStorage.getItem(tokenKey);
    return jwtDecode(data);
  } catch (ex) {
    return null;
  }
}
const auth = {
  login,
  logout,
  savePassword,
  getCurrentUser,
};

export default auth;
