import { useContext } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { MyContext } from "../App";
import "./common/news.css";

const Newspage = () => {
  const { news } = useContext(MyContext);
  return (
    <div>
      <h1>News</h1>
      <div className="new">
        {news &&
          news.map((n) => (
            <div className="card" key={n.id}>
              <img src={n.imgurl} alt="news logo" />
              <h3>{n.headline}</h3>
              <p>
                <span>
                  <i className="fa fa-user" aria-hidden="true"></i> {n.author}
                </span>
              </p>
              <NavLink className="read" to={`/editnews/${n.id}`}>
                Edit news
              </NavLink>
              <Outlet />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Newspage;
