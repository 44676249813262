import { useEffect, useState } from "react";
import { getCurrentUser } from "./../services/authService";

export default function useAdmin() {
  const [user, setUser] = useState();
  useEffect(() => {
    const getUser = () => {
      const data = getCurrentUser();
      setUser(data);
    };
    getUser();
  }, []);

  return user;
}
