const Input = ({ name, type, label, value, onChange, error }) => {
  return (
    <div className="formInput">
      <label htmlFor={name}>{label}</label>
      <input
        value={value}
        onChange={onChange}
        type={type}
        name={name}
        id={name}
      />
      {error && <p>{error}</p>}
    </div>
  );
};

export default Input;
