import { useEffect, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { getDatas, Delete } from "./services/userService";

const Users = () => {
  const [user, setUser] = useState([]);
  useEffect(() => {
    const getUser = async () => {
      try {
        const { data } = await getDatas();
        setUser(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getUser();
  }, []);
  const Handledelete = async (id) => {
    await Delete(id);
  };
  return (
    <div>
      <h3>Users</h3>
      {user &&
        user.map((u) => (
          <div key={u.id} className="user">
            <p>{u.username}</p>
            <p>{u.phone}</p>
            <button onClick={() => Handledelete(u.id)}>Delete</button>
          </div>
        ))}
      <NavLink to="signup">Create new user</NavLink>
      <Outlet />
    </div>
  );
};

export default Users;
