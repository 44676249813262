import { useEffect, useState } from "react";
import { getLogo } from "../services/footballData";

export default function useLogo() {
  const [data, setData] = useState();

  useEffect(() => {
    const getData = async () => {
      const { data } = await getLogo();
      setData(data);
    };
    getData();
  }, []);
  return data;
}
