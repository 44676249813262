import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { MyContext } from "../../App";

const Fixturefot = () => {
  const { data } = useContext(MyContext);
  return (
    data &&
    data.map((d) => (
      <div key={d.id} className="tablefot">
        <button className="item1">
          {"Round - " + d.round} | Ethiopian Premier League
        </button>
        <div className="livediv">
          <div key={d.id} className="item2">
            <span className="leftName">{d.homeName}</span>
            <img src={d.homeURL} alt={d.homeName + "logo"} />
            <button className="time">{d.time}</button>
            <img src={d.awayURL} alt={d.awayName + "logo"} />
            <span className="rightName">{d.awayName}</span>
            <NavLink to={`/liveData/${d.id}`}>Live</NavLink>
          </div>
        </div>
      </div>
    ))
  );
};

export default Fixturefot;
