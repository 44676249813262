import React, { createContext, useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import useData from "./routes/customHook/useData";
import useLogo from "./routes/customHook/useLogo";
import useNews from "./routes/customHook/useNews";
import Initail from "./routes/initial";
import Live from "./routes/common/liveData";
import News from "./routes/common/news";
import SignUP from "./routes/createUser";
import Fixture from "./routes/fixture";
import Logo from "./routes/common/logo";
import Fixturefot from "./routes/common/fixturefot";
import Newspage from "./routes/newsPage";
import Changepass from "./routes/common/changepassword";
import "./App.css";
import Editnews from "./routes/common/editnews";
import Users from "./routes/users";

const MyContext = createContext();

const App = () => {
  const [data, setData] = useState(null);
  const [news, setNews] = useState(null);
  const [logo, setLogo] = useState(null);
  const fetchData = useData();
  const fetchNews = useNews();
  const fetchLogo = useLogo();

  useEffect(() => {
    setData(fetchData);
    setNews(fetchNews);
    setLogo(fetchLogo);
  }, [fetchData, fetchNews, fetchLogo]);
  return (
    <MyContext.Provider value={{ data, news, logo }}>
      <Routes>
        <Route path="/" element={<Initail />}>
          <Route index element={<Newspage />} />
          <Route path="news" element={<News />} />
          <Route path="users" element={<Users />}>
            <Route path="signup" element={<SignUP />} />
          </Route>
          <Route path="changepassword" element={<Changepass />} />
          <Route path="editnews/:id" element={<Editnews />} />
          <Route path="fixturefot" element={<Fixturefot />} />
          <Route path="liveData/:id" element={<Live />} />
          <Route path="fixture" element={<Fixture />} />
          <Route path="logo" element={<Logo />} />
        </Route>
      </Routes>
    </MyContext.Provider>
  );
};

export { App, MyContext };
