import { useState } from "react";
import { registerRed } from "../services/footballData";
import Input from "./input";
import "./modal.css";

const Redmodal = ({ isOpen, closeModal }) => {
  const [data, setData] = useState({
    matchnum: "",
    name: "",
    time: "",
    clubname: "",
    clubstatus: "",
  });
  const handleChange = ({ target: { name, value } }) => {
    const product = { ...data };
    product[name] = value;
    setData(product);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    doSubmit();
  };
  const doSubmit = async () => {
    await registerRed(data);
  };
  const renderInput = (name, label, type = "text") => {
    return (
      <Input
        onChange={handleChange}
        label={label}
        type={type}
        name={name}
        value={data[name]}
      />
    );
  };
  return (
    <>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <button className="close-button" onClick={closeModal}>
              X
            </button>
            <div className="modal-content">
              <h3>Register Red Card</h3>
              <form className="uploadForm" onSubmit={handleSubmit}>
                {renderInput("name", "Player name")}
                {renderInput("time", "Time")}
                {renderInput("clubname", "Club Name")}
                {renderInput("clubstatus", "Club Status(hometeam / awayteam)")}
                {renderInput("matchnum", "Match number")}

                <button className="formBtn" type="submit">
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Redmodal;
