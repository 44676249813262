const TextArea = ({ name, type, label, value, onChange }) => {
  return (
    <div className="formInput">
      <label htmlFor={name}>{label}</label>
      <textarea
        value={value}
        onChange={onChange}
        type={type}
        name={name}
        id={name}
        rows="4"
        cols="50"
      />
    </div>
  );
};

export default TextArea;
