import React, { useState } from "react";
import Joi from "joi-browser";
import Input from "./input";
import auth from "../services/authService";
import useAdmin from "../customHook/useAdmin";

const Changepass = () => {
  const user = useAdmin();
  const [data, setData] = useState({ phone: "", password1: "", password2: "" });
  const [errors, setErrors] = useState({});
  const schema = {
    phone: Joi.number().required().label("Phone"),
    password1: Joi.string().required().label("Password1"),
    password2: Joi.string().required().label("Password2"),
  };
  const validate = () => {
    const option = { abortEarly: false };
    const { error } = Joi.validate(data, schema, option);
    if (!error) return null;
    const errors = {};
    error.details.map((item) => (errors[item.path[0]] = item.message));
    return errors;
  };
  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema1 = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schema1);
    return error ? error.details[0].message : null;
  };
  const handleChange = ({ currentTarget: input }) => {
    const errors1 = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) errors1[input.name] = errorMessage;
    else delete errors1[input.name];
    const data1 = { ...data };
    data1[input.name] = input.value;
    setData(data1);
    setErrors(errors1);
  };
  const renderInput = (name, label, type = "text") => {
    return (
      <Input
        onChange={handleChange}
        label={label}
        type={type}
        name={name}
        value={data[name]}
        error={errors[name]}
      />
    );
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate(data, schema);
    setErrors(errors || {});
    if (errors) return;
    doSubmit();
  };
  const doSubmit = async () => {
    try {
      await auth.savePassword(user, data);
      sessionStorage.removeItem("token");
      window.location.reload();
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors1 = { ...errors };
        errors1.phone = ex.response.data;
        setErrors(errors1);
      }
    }
  };

  return (
    <div className="login">
      <form onSubmit={handleSubmit}>
        {renderInput("phone", "Phone", "tel")}
        {renderInput("password1", "Current Password", "password")}
        {renderInput("password2", "New Password", "password")}
        <button className="loginBtn" type="submit">
          Save
        </button>
      </form>
    </div>
  );
};

export default Changepass;
