import { useContext } from "react";
import { MyContext } from "../../App";

const Logourl = () => {
  const { logo } = useContext(MyContext);
  return (
    <div className="url">
      <table>
        <tbody>
          {logo &&
            logo.map((m) => (
              <tr key={m.id}>
                <td>{m.url}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default Logourl;
