import Fixturedata from "./common/fixtureData";
import Logourl from "./common/logourl";

const Fixture = () => {
  return (
    <div className="dis">
      <Fixturedata />
      <Logourl />
    </div>
  );
};

export default Fixture;
