import Axios from "axios";

Axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status <= 500;
  if (!expectedError) {
    console.error("Unexpected error occurred:", error);
    alert("Unexpected error occurred. Please try again later.");
  }
  return Promise.reject(error);
});
const http = {
  get: Axios.get,
  put: Axios.put,
  post: Axios.post,
  delete: Axios.delete,
};

export default http;
