import { useState } from "react";
import axios from "axios";
import Input from "./input";
import TextArea from "./textArea";
import config from "../../config.json";

const apiNews = config.apiUrl + "/news";
const News = () => {
  const [data, setData] = useState({
    headline: "",
    author: "",
    filenumber: "",
    content: "",
  });
  const [file, setFile] = useState(null);
  const handleDataChange = ({ target: { name, value } }) => {
    const news = { ...data };
    news[name] = value;
    setData(news);
  };
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("headline", data.headline);
      formData.append("author", data.author);
      formData.append("filenumber", data.filenumber);
      formData.append("content", data.content);
      formData.append("file", file);
      const response = await axios.post(apiNews, formData);
      console.log(response.data);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
    window.location = "/news";
  };
  const renderInput = (name, label, type = "text") => {
    return (
      <Input
        onChange={handleDataChange}
        label={label}
        type={type}
        name={name}
        value={data[name]}
      />
    );
  };
  const renderTextarea = (name, label, type = "text") => {
    return (
      <TextArea
        onChange={handleDataChange}
        label={label}
        type={type}
        name={name}
        value={data[name]}
      />
    );
  };
  return (
    <div className="news">
      <form
        onSubmit={handleFormSubmit}
        encType="multipart/form-data"
        className="picForm"
      >
        {renderInput("headline", "Headline")}
        {renderInput("filenumber", "File number")}
        {renderTextarea("content", "Content")}
        {renderInput("author", "Author")}
        <label htmlFor="file" className="custom-file-upload">
          {file ? file.name : "Upload header image"}
        </label>
        <input
          className="file-upload-input"
          type="file"
          name="file"
          id="file"
          required
          accept="image/*"
          onChange={handleFileChange}
        />
        <button className="upload-button" type="submit">
          Upload
        </button>
      </form>
    </div>
  );
};

export default News;
