import htt from "./httpService2";

export function register(user) {
  try {
    return htt.post("/user", {
      username: user.username,
      phone: user.phone,
      password: user.password,
    });
  } catch (error) {
    alert(error);
  }
}

export function getDatas() {
  try {
    return htt.get("/user");
  } catch (error) {
    alert(error);
  }
}
export function getData(dataId) {
  try {
    return htt.get("/user/" + dataId);
  } catch (error) {
    alert(error);
  }
}

export function Delete(id) {
  try {
    return htt.delete("/user/" + id);
  } catch (error) {
    alert(error);
  }
}
